<template>
  <div class="container">
    <header-back name='号卡明细'>
      <template slot="seachRight">
        <span
        v-if="userType===4"
          style="color:#1989fa;margin-right:20px"
          @click='onAddCard()'
        >提现信息</span>
        <span @click="onExport"> 下载
          <van-icon name="down" />
        </span>
      </template>
    </header-back>
    <section class="top44">
       <div class="revenue-cen mt16" style="background-color: #dcdee0;">
        <div class="tit-l">
          <p>支付宝账号：{{ alipayNo}}</p>

        </div>
     
      </div>
      <div class="sta-cen mt16">
        <van-field
          v-model="phone"
          type='tel'
          clearable
          name="手机号码"
          label="手机号码"
          placeholder="手机号码"
        />
        <van-field
          name="radio"
          label="状态"
        >
          <template #input>
            <van-radio-group
              v-model="radio"
              direction="horizontal"
            >
              <van-radio
                icon-size="16px"
                checked-color="#94FF75"
                v-for="(item,index) in radioList"
                :key='index'
                :name="item.name"
              >{{item.value}}</van-radio>

            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          name="calendar"
          :value="value"
          label="下单日期"
          label-width='7em'
          placeholder="选择日期区间"
          @click="showCalendar = true"
        >
          <template
            #right-icon
            v-if="value"
          >
            <van-icon
              name="clear"
              color="#c8c9cc"
              @click.stop="onClearable"
            />
          </template>

        </van-field>
        <van-calendar
          type="range"
          :min-date="minDate"
          :max-date="maxDate"
          :color="variables.textColor"
          v-model="showCalendar"
          allow-same-day
          @confirm="onConfirm"
        >

        </van-calendar>
          <van-field
          readonly
          name="calendar"
          :value="value2"
          label="激活日期"
          label-width='7em'
          placeholder="选择日期区间"
          @click="showCalendar2 = true"
        >
          <template
            #right-icon
            v-if="value2"
          >
            <van-icon
              name="clear"
              color="#c8c9cc"
              @click.stop="onClearable2"
            />
          </template>

        </van-field>
        <van-calendar
          type="range"
          :min-date="minDate"
          :max-date="maxDate"
          :color="variables.textColor"
          v-model="showCalendar2"
          allow-same-day
          @confirm="onConfirm2"
        >

        </van-calendar>
        <div class="div-btn">
          <van-button
            round
            type="primary"
            :color="variables.textColor"
            size="small"
            @click="onSave"
          >确定</van-button>
        </div>
      </div>
      <div class="revenue-cen mt16">
        <div class="tit-l">
          <p>订单数量：{{ orderNum}}</p>

        </div>
        <div class="tit-r">
          <p>做单收入：<span class="tit-red">￥{{ totalAmount}}</span></p>
        </div>
      </div>

      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :offset='offset'
          finished-text=""
          @load="onLoad"
        >
          <div class="van-clearfix">
            <div class="sta-m ">
              <div
                class="sta-mm mt16"
                v-for="(item,index) in orderList"
                :key='index'
              >
             <van-cell
                  value=""
                  title="金额"
                >
                  <div slot="right-icon">
                    <span>￥{{ item.amount || 0}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="工号"
                >
                  <div slot="right-icon">
                    <span>{{item.username}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="名称"
                >
                  <div slot="right-icon">
                    <span>{{item.nickname}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="订单号"
                >
                  <div slot="right-icon">
                    <span>{{item.upid}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="订单时间"
                >
                  <div slot="right-icon">
                    <span>{{item.add_time}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="激活时间"
                  v-if="item.retcode===3"
                >
                  <div slot="right-icon">
                    <span>{{item.activate_time}}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="订单号码"
                >
                  <div slot="right-icon">
                    <span>{{item.tel }}</span>
                  </div>
                </van-cell>
                <van-cell
                  value=""
                  title="订单地址"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{ item.province }}{{ item.city }}{{ item.county }}{{ item.address_detail }}</span>
                  </div>
                </van-cell>
                  <van-cell
                  value=""
                  title="快递单号"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                    <span>{{ item.express_num }}</span>
                    
                  </div>
                </van-cell>
               <van-cell
                  value=""
                  title="订单状态"
                >
                  <div
                    class="reason"
                    slot="right-icon"
                    style="max-width:64%"
                  >
                 
                     <span  v-if="item.retcode < 4">{{ item.remark }}</span>
                    <span v-else>
                      {{item.retcode | HkOrderState()}}
                    </span>
                  </div>
                </van-cell>
               
                <div class="div-tit" :style="{'color':item.retcode===2 ? 'red' : '#53d42d'}">{{item.retcode | HkOrderState()}}</div>
              </div>

            </div>
          </div>
          <div
            style="color: #969799;font-size: 14px;text-align: center;padding-bottom:20px"
            v-if="noData"
          >
            <span> 没有更多了</span>
          </div>
        </van-list>
      </van-pull-refresh>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";

import variables from "@/styles/variables.scss";
import API from "../../api/http";

export default {
  components: { HeaderBack },
  data() {
    return {
      phone: "",
      value: "",
      value2: "",
      showCalendar: false,
      showCalendar2: false,
      orderNum: 0,
      totalAmount: 0,
      vanImg: require("../../assets/image/person/order.png"),
      orderList: [],
      radio: "0",
      radioList: [
        {
          value: "全部",
          name: "0"
        },
        {
          value: "成功",
          name: "1"
        },
        {
          value: "失败",
          name: "2"
        },
        {
          value: "激活",
          name: "3"
        }
      ],
      listQuery: {
        page: 1,
        status: "0"
      },
      loading: false,
      finished: true,
      refreshing: false,
      noData: false,
      offset: 50,
      startDate: "", //开始日期
      endDate: "", //结束日期
      minDate: "",
      maxDate: new Date(),
      orderNum: 0,
      acStartDate: "",
      acEndDate: "",
      alipayNo: ""
    };
  },
  computed: {
    variables() {
      return variables;
    },
    userType() {
      return this.$store.getters["user/userType"];
    }
  },

  created() {
    this.getBankList();
    this.setIsDate();
    this.onLoad();
  },
  methods: {
    getBankList() {
      API.getUserBankList().then(res => {
        let list = res.data.list;

        if (list.length > 0) {
          let data = list[0];
          this.alipayNo = data.alipayAccount;
        }
      });
    },
    getInit(val) {
      API.postHKList(this.listQuery)
        .then(res => {
          let data = res.data;
          this.loading = false;
          this.orderNum = data.total;
          this.totalAmount = data.totalAmount;
          if (data.list.length > 0) {
            let list = data.list;
            if (data.pages === 1) {
              this.finished = true;
              this.noData = true;
            }

            if (val) {
              this.refreshing = false;

              this.orderList = list;
            } else {
              this.orderList = this.orderList.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.refreshing = false;
            this.finished = true;
            this.noData = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onLoad() {
      if (this.refreshing) {
        this.orderList = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.finished = false;
      this.loading = true;
      this.noData = false;
      this.getInit();
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      this.noData = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.orderList = [];
      this.getInit(1);
    },

    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.showCalendar = false;

      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";

      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";

      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    onConfirm2(date) {
      const [start, end] = date;
      this.showCalendar2 = false;

      this.acStartDate =
        utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";

      this.acEndDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";

      this.value2 = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    setIsDate() {
      let start = new Date();
      start.setTime(start.getTime() - 4 * 24 * 60 * 60 * 1000);
      let end = new Date();
      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";
      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";
      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.listQuery.startDate = this.startDate;
      this.listQuery.endDate = this.endDate;
      this.minDate = new Date(start);
    },
    onClearable() {
      this.value = "";
      this.startDate = "";
      this.endDate = "";
    },
    onClearable2() {
      this.value2 = "";
      this.acStartDate = "";
      this.acEndDate = "";
    },
    onSave() {
      //确定
      let obj = {
        page: 1,
        status: this.radio,
        sort: "retail_price",
        order: "desc" //desc:将序  asc:升序
      };
      if (this.phone.trim()) {
        obj.mobile = this.phone;
      }
      if (this.startDate) {
        obj.startDate = this.startDate;
        obj.endDate = this.endDate;
      }
      if (this.acStartDate) {
        obj.acStartDate = this.acStartDate;
        obj.acEndDate = this.acEndDate;
      }
      this.listQuery = obj;

      this.onRefresh();
    },
    onExport() {
      //导出
      let data = {
        page: 1,
        status: this.radio,
        export: 1
      };
      if (this.listQuery.startDate) {
        data.startDate = this.listQuery.startDate;
        data.endDate = this.listQuery.endDate;
      }
      if (this.phone.trim()) {
        data.mobile = this.phone;
      }
      if (this.listQuery.acStartDate) {
        data.acStartDate = this.listQuery.acStartDate;
        data.acEndDate = this.listQuery.acEndDate;
      }
      API.exportHKExport(data, "号卡明细");
    },
    onAddCard() {
      //添加信息
      this.$router.push("/cardAddBank");
    }
  }
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 0 15px;
  box-sizing: border-box;
}
.sta-cen {
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  .div-btn {
    text-align: center;
    padding: 10px 0;
    .van-button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .van-radio {
    padding-bottom: 8px;
  }
}
.sta-m {
  padding-bottom: 20px;
}
.sta-mm {
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  .van-cell {
    color: #777;
  }
  .div-tit {
    font-size: 14px;
    text-align: center;
    padding: 10px 16px;
    color: #53d42d;
  }
}
.van-text-color {
  color: $textColor;
}
.van-text-red {
  color: $redColor;
}

.revenue-cen {
  background-color: #fff;
  padding: 16px;
  margin-top: 16px;
  border-radius: 6px;
  font-size: 14px;
  color: #515151;
  display: flex;
  & > div {
    flex: 1;
    & > div {
      font-weight: 500;
      line-height: 28px;
    }
  }
  .tit-r {
    padding-left: 30px;
  }
  .tit-red {
    color: $redColor;
  }
}
</style>
